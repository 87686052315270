// QuizStyled.js
import styled from "styled-components";

export const QuizContainer = styled.div`
  text-align: center;
  //margin: 20px;
  max-width: 998px;
  margin: 20px auto;

  .progress-bar {
    background: rgba(0, 123, 255, 0.2);
    height: 10px;
  }

  .progress-bar-fill {
    background: #007bff;
    height: 10px;
  }
  h1 {
    margin-top: 50px;
  }
`;

export const QuestionContainer = styled.div`
  margin: 20px 0;
  input {
    outline: none;
    border: none;
    border-bottom: 1px solid #000;
    text-align: center;

    &:hover {
      cursor: pointer;
    }
  }

  .liusherColors {
    &:hover {
      cursor: pointer;
    }
  }

  .bassa-darki {
    display: flex;
    justify-content: center;
    align-items: center;

    label {
      margin: 0 20px;

      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const RadioLabel = styled.label`
  margin-right: 10px;
`;

export const Select = styled.select`
  margin-top: 10px;
  padding: 5px;
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
`;

export const Button = styled.button`
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const WrapPositionFixedDix = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  h2 {
    margin-top: 200px;
    text-align: center;
  }
`;
