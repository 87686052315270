import React, { useEffect, useMemo } from "react";

import {
  Table,
  WrapHead,
  WrapAnswers,
  AnswersItem,
  HeadInner,
  WrapSpan,
} from "./rotated-style";

const calculateCopingStrategiesScores = (answers) => {
  const answerMappings = {
    1: 3,
    2: 2,
    3: 1,
  };

  const scales = {
    "Разрешение проблем": [2, 3, 8, 9, 11, 15, 16, 17, 20, 29, 33],
    "Поиск социальной поддержки": [1, 5, 7, 12, 14, 19, 23, 24, 25, 31, 32],
    "Избегание проблем": [4, 6, 10, 13, 18, 21, 22, 26, 27, 28, 30],
  };

  let scores = {};

  for (let scale in scales) {
    let scaleScore = 0;
    scales[scale].forEach((index) => {
      scaleScore += answerMappings[answers[index - 1]] || 0;
    });
    scores[scale] = scaleScore;
  }

  return scores;
};

const getInterpretation = (score, scale) => {
  switch (scale) {
    case "Разрешение проблем":
      if (score < 16) return `Очень низкий (${score})`;
      if (score >= 17 && score <= 21) return `Низкий (${score})`;
      if (score >= 22 && score <= 30) return `Средний (${score})`;
      return `Высокий (${score})`;
    case "Поиск социальной поддержки":
      if (score < 13) return `Очень низкий (${score})`;
      if (score >= 14 && score <= 18) return `Низкий (${score})`;
      if (score >= 19 && score <= 28) return `Средний (${score})`;
      return `Высокий (${score})`;
    case `Избегание проблем`:
      if (score < 15) return `Очень низкий (${score})`;
      if (score >= 16 && score <= 23) return `Низкий (${score})`;
      if (score >= 24 && score <= 26) return `Средний (${score})`;
      return `Высокий (${score})`;
    default:
      return `(${score})`;
  }
};

const RotatedTable = (props) => {
  const { answers, coefficients, processedData, setProcessedData } = props;
  console.log("answers", answers);
  const headers = [
    "Укажите Ваш пол",
    "Введите ваш возраст(числом)",
    "Укажите Ваш стаж работы врачом акушером-гинекологом",
    "Входят ли в ваши обязанности вопросы касаемо абортов?",
    "Если Вы занимаетесь вопросами абортов, то укажите как долго?",
    "Проведение абортов для Вас",
    'Кда"-" 0,4',
    'Ккп"+" 0,8',
    'Квн"-" 0,4',
    'Кизб "-" 0,5',
    'Ксопр "-" 0,4',
    'Кдо "-" 0,4',
    'Кмечт "-" 0,3',
    'Кчэ "+" 0,5',
    'К конфл "+" 0,7',
    'Какт "-" 0,5',
    "физическая",
    "вербальная",
    "косвенная",
    "негативизм",
    "раздражение",
    "подозрительность",
    "обида",
    "аутоагрессия",
    "Индекс агрессивности: ИА",
    "Индекс враждебности: ИВ",
    "%ТФЛ",
    "бал ТФЛ ",
    "Разрешение проблем",
    "Поиск социальной поддержки",
    "Избегание проблем",
    "Психоэмоциональное истощение",
    "Деперсонализация» (личностное отдаление)",
    "Редукция личных достижений» (профессиональная мотивация",
    "Психическое выгорание",
  ];

  const convertedAnswers = answers
    .slice(15, 88)
    .map((answer) => (answer === "ДА" || answer === "Пожалуй, да" ? "+" : "-"));

  const scoring = useMemo(
    () => ({
      "1. физическая": {
        pos: [1, 25, 33, 41, 48, 55, 62, 68],
        neg: [9, 17],
        k: 11,
      },
      "2. вербальная": {
        pos: [7, 15, 23, 31, 46, 53, 60, 71, 73],
        neg: [39, 66, 74, 75],
        k: 8,
      },
      "3. косвенная": {
        pos: [2, 10, 18, 34, 42, 56, 63],
        neg: [26, 49],
        k: 13,
      },
      "4. негативизм": { pos: [4, 12, 20, 28], neg: [36], k: 20 },
      "5. раздражение": {
        pos: [3, 19, 27, 43, 50, 57, 64, 72],
        neg: [11, 35, 69],
        k: 9,
      },
      "6. подозрительность": {
        pos: [6, 14, 22, 30, 38, 45, 52, 59],
        neg: [65, 70],
        k: 11,
      },
      "7. обида": { pos: [5, 13, 21, 29, 37, 44, 51, 58], neg: [], k: 13 },
      "8. аутоагрессия": {
        pos: [8, 16, 24, 32, 40, 47, 54, 61, 67],
        neg: [],
        k: 11,
      },
    }),
    [],
  );

  const shkalas = useMemo(
    () => ({
      Эгоизм: [2, 20, 36, 47, 59],
      Жадность: [13, 22, 49, 61],
      Макиавеллизм: [4, 14, 23, 31, 38, 50, 62],
      "Моральное дистанцирование": [9, 27, 43, 55, 67],
      Нарциссизм: [5, 15, 32, 39, 51, 63],
      "Психологическое право": [7, 25, 41, 53, 65],
      Психопатия: [6, 16, 24, 33, 40, 52, 64],
      Садизм: [8, 17, 26, 34, 42, 54, 66, 70],
      Самоцентризм: [28, 44, 56, 68],
      Злорадность: [10, 18, 29, 45, 57, 69],
      "Аморализм -Crudelia": [1, 11, 19, 30, 35, 46, 58],
      "Аморализм-Frustralia": [3, 12, 21, 37, 48, 60],
    }),
    [],
  );
  const negativeKeys = useMemo(
    () => [
      1, 2, 4, 5, 7, 9, 10, 12, 15, 17, 23, 25, 27, 31, 33, 34, 35, 37, 38, 40,
      41, 42, 46, 47, 51, 52, 54, 56, 61, 63, 65, 66, 68, 69, 70,
    ],
    [],
  );
  const scores = useMemo(() => {
    const tempScores = {};

    for (let form in scoring) {
      let sum = 0;

      // Add for positive matches
      scoring[form].pos.forEach((i) => {
        if (convertedAnswers[i - 1] === "+") sum++;
      });

      // Subtract for negative matches
      scoring[form].neg.forEach((i) => {
        if (convertedAnswers[i - 1] === "-") sum++;
      });

      tempScores[form] = sum * scoring[form].k;
    }

    return tempScores;
  }, [convertedAnswers, scoring]);
  // console.log("tempScores", scores);

  useEffect(() => {
    let tempProcessedData = [...answers.slice(0, 6)];

    const coeffArray = Object.values(coefficients).map((value) =>
      typeof value === "number" ? value.toFixed(2) : value,
    );

    const alreadyInserted = coeffArray.every((coeff) =>
      answers.includes(coeff),
    );

    if (!alreadyInserted && answers[13] !== "" && coeffArray.length === 10) {
      tempProcessedData = [...tempProcessedData, ...coeffArray];
    }

    const IA =
      (scores["1. физическая"] +
        scores["2. вербальная"] +
        scores["3. косвенная"]) /
      3;
    const IV = (scores["6. подозрительность"] + scores["7. обида"]) / 2;

    if (answers[88]) {
      tempProcessedData = [
        ...tempProcessedData,
        scores["1. физическая"],
        scores["2. вербальная"],
        scores["3. косвенная"],
        scores["4. негативизм"],
        scores["5. раздражение"],
        scores["6. подозрительность"],
        scores["7. обида"],
        scores["8. аутоагрессия"],
        IA.toFixed(2),
        IV.toFixed(2),
      ];
    }

    if (answers[159]) {
      const shkalaScores = {};
      let valuesNumbers = answers.slice(89, 160);
      for (let shkala in shkalas) {
        let sum = 0;
        shkalas[shkala].forEach((item) => {
          let answerValue = +valuesNumbers[item - 1];
          if (negativeKeys.includes(item)) {
            answerValue = 6 - answerValue;
          }
          sum += answerValue;
        });
        shkalaScores[shkala] = sum / shkalas[shkala].length;
      }

      // console.log("shkalaScores", JSON.stringify(shkalaScores));

      const totalScore = Object.values(shkalaScores).reduce((a, b) => a + b, 0);
      const percentTFL = (totalScore * 100) / 350;
      const scaleTFL = (percentTFL * 5) / 100;

      // Format the values properly before adding to the tempProcessedData array
      tempProcessedData = [
        ...tempProcessedData,
        shkalaScores["Эгоизм"].toFixed(2),
        shkalaScores["Жадность"].toFixed(2),
        shkalaScores["Макиавеллизм"].toFixed(2),
        shkalaScores["Моральное дистанцирование"].toFixed(2),
        shkalaScores["Нарциссизм"].toFixed(2),
        shkalaScores["Психологическое право"].toFixed(2),
        shkalaScores["Психопатия"].toFixed(2),
        shkalaScores["Садизм"].toFixed(2),
        shkalaScores["Самоцентризм"].toFixed(2),
        shkalaScores["Злорадность"].toFixed(2),
        shkalaScores["Аморализм -Crudelia"].toFixed(2),
        shkalaScores["Аморализм-Frustralia"].toFixed(2),
        percentTFL.toFixed(2),
        scaleTFL.toFixed(2), // Ensure it's formatted to two decimal places
      ];
    }
    // console.log("tempProcessedData", tempProcessedData);

    if (answers[192] !== "") {
      const copingScores = calculateCopingStrategiesScores(
        answers.slice(160, 191),
      );
      const copingInterpretations = {};
      for (let scale in copingScores) {
        copingInterpretations[scale] = getInterpretation(
          copingScores[scale],
          scale,
        );
      }
      tempProcessedData = [
        ...tempProcessedData,
        ...Object.values(copingInterpretations),
      ];
    }

    if (answers[214] !== "") {
      const vigoranieAnswers = answers.slice(192, 214);
      console.log("vigoranieAnswers", vigoranieAnswers);

      const emotionalExhaustionIndices = [1, 2, 3, 6, 8, 13, 14, 16, 20];
      const depersonalizationIndices = [5, 10, 11, 15, 22];
      const reducedAccomplishmentIndices = [4, 7, 9, 12, 17, 18, 19, 21];
      console.log("vigoranieAnswers", vigoranieAnswers);
      const emotionalExhaustionScore = emotionalExhaustionIndices.reduce(
        (acc, index) => acc + parseInt(+vigoranieAnswers[index - 1]),
        0,
      );

      const depersonalizationScore = depersonalizationIndices.reduce(
        (acc, index) => acc + parseInt(+vigoranieAnswers[index - 1]),
        0,
      );

      const reducedAccomplishmentScore = reducedAccomplishmentIndices.reduce(
        (acc, index) => acc + parseInt(+vigoranieAnswers[index - 1]),
        0,
      );

      const burnoutScore =
        emotionalExhaustionScore +
        depersonalizationScore +
        reducedAccomplishmentScore;

      // Update tempProcessedData with the calculated scores
      tempProcessedData = [
        ...tempProcessedData,
        emotionalExhaustionScore,
        depersonalizationScore,
        reducedAccomplishmentScore,
        burnoutScore,
        // ... (existing tempProcessedData values)
      ];
    }
    // Only update state if data has changed
    if (JSON.stringify(tempProcessedData) !== JSON.stringify(processedData)) {
      setProcessedData(tempProcessedData);
    }
  }, [answers, coefficients, scores, negativeKeys, shkalas]);

  return (
    <Table>
      <WrapHead>
        {headers.map((header, index) => (
          <HeadInner key={index}>
            <WrapSpan>{header}</WrapSpan>
          </HeadInner>
        ))}
      </WrapHead>
      <WrapAnswers>
        {processedData.map((answer, index) => (
          <AnswersItem key={index}>{answer}</AnswersItem>
        ))}
      </WrapAnswers>
    </Table>
  );
};

export default RotatedTable;
