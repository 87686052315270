import React, { useEffect, useState } from "react";

export const Liusher = (props) => {
  const { selectedColors, coefficients, setCoefficients } = props;
  const [liusherNumbers, setLiusherNumbers] = useState([]);

  useEffect(() => {
    if (selectedColors.length >= 7) {
      const getColorNumber = (color) => {
        switch (color) {
          case "grey":
            return 0;
          case "blue":
            return 1;
          case "green":
            return 2;
          case "violet":
            return 5;
          case "black":
            return 7;
          case "brown":
            return 6;
          case "yellow":
            return 4;
          case "red":
            return 3;
          default:
            return -1;
        }
      };
      const numbers = selectedColors.map((color) => getColorNumber(color));
      setLiusherNumbers(numbers);
    }
  }, [selectedColors, selectedColors.length]);

  useEffect(() => {
    if (liusherNumbers.length === 8) {
      const ranking = {
        grey: liusherNumbers.indexOf(0) + 1,
        blue: liusherNumbers.indexOf(1) + 1,
        green: liusherNumbers.indexOf(2) + 1,
        red: liusherNumbers.indexOf(3) + 1,
        yellow: liusherNumbers.indexOf(4) + 1,
        violet: liusherNumbers.indexOf(5) + 1,
        brown: liusherNumbers.indexOf(6) + 1,
        black: liusherNumbers.indexOf(7) + 1,
      };

      const coefficients = {
        Kda: ranking.yellow / (ranking.blue + ranking.black),
        Kkp: ranking.black / (ranking.blue + ranking.green),
        Kvn: ranking.green / (ranking.yellow + ranking.brown),
        Kizb: ranking.green / (ranking.grey + ranking.black),
        Ksopr: ranking.green / (ranking.brown + ranking.black),
        Kdo: ranking.blue / (ranking.grey + ranking.brown),
        Kmecht: ranking.yellow / (ranking.green + 7),
        Kche: ranking.violet / (ranking.yellow + ranking.grey),
        Kkonf: ranking.black / (ranking.violet + ranking.blue),
        Kakt: ranking.red / (ranking.black + ranking.blue),
      };

      setCoefficients(coefficients);
    }
  }, [selectedColors.length, liusherNumbers, setCoefficients]);

  return (
    <>
      <div>Люшер данные: {liusherNumbers.join(", ")}</div>
      <div>
        <p>Коэффициенты:</p>
        <ul>
          {Object.entries(coefficients).map(([key, value]) => (
            <li key={key}>
              {key}: {value.toFixed(2)}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
