import React, { useState } from "react";

const DeleteDataButton = () => {
  const [responseMessage, setResponseMessage] = useState(null);

  const handleDelete = async () => {
    try {
      const response = await fetch(
        "https://admin.mame-darom.ru/wp-json/custom/v1/endpoint",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (response.ok) {
        const jsonData = await response.json();
        setResponseMessage(jsonData.message);
      } else {
        console.error("Failed to delete data:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div style={{ display: "none" }}>
      <button onClick={handleDelete}>удалить</button>
      {responseMessage && <p>ОТвет сервера: {responseMessage}</p>}
    </div>
  );
};

export default DeleteDataButton;
