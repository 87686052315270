import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import {
  Table,
  WrapHead,
  WrapAnswers,
  AnswersItem,
  HeadInner,
  WrapSpan,
} from "./rotated-style";

const Results = () => {
  const headers = [
    "Укажите Ваш пол",
    "Введите ваш возраст(числом)",
    "Укажите Ваш стаж работы врачом акушером-гинекологом",
    "Входят ли в ваши обязанности вопросы касаемо абортов?",
    "Если Вы занимаетесь вопросами абортов, то укажите как долго?",
    "Проведение абортов для Вас",
    'Кда"-" 0,4',
    'Ккп"+" 0,8',
    'Квн"-" 0,4',
    'Кизб "-" 0,5',
    'Ксопр "-" 0,4',
    'Кдо "-" 0,4',
    'Кмечт "-" 0,3',
    'Кчэ "+" 0,5',
    'К конфл "+" 0,7',
    'Какт "-" 0,5',
    "физическая",
    "вербальная",
    "косвенная",
    "негативизм",
    "раздражение",
    "подозрительность",
    "обида",
    "аутоагрессия",
    "Индекс агрессивности: ИА",
    "Индекс враждебности: ИВ",
    "Эгоизм",
    "Жадность",
    "Макиавеллизм",
    "Моральное дистанцирование",
    "Нарциссизм",
    "Психологическое право",
    "Психопатия",
    "Садизм",
    "Самоцентризм",
    "Злорадность",
    "Аморализм -Crudelia",
    "Аморализм-Frustralia",
    "%ТФЛ",
    "бал ТФЛ ",
    "Разрешение проблем",
    "Поиск социальной поддержки",
    "Избегание проблем",
    "Психоэмоциональное истощение",
    "Деперсонализация» (личностное отдаление)",
    "Редукция личных достижений» (профессиональная мотивация",
    "Психическое выгорание",
  ];

  const [processedDataFromServer, setProcessedDataFromServer] = useState([]);

  useEffect(() => {
    // Define the endpoint URL
    const endpointUrl =
      "https://admin.mame-darom.ru/wp-json/custom/v1/endpoint";

    // Fetch data from the endpoint
    fetch(endpointUrl)
      .then((response) => response.json())
      .then((data) => {
        // Map and extract the "processed_answers" data from the response
        const processedAnswers = data.map((item) => {
          const content = JSON.parse(item.content);
          return content.submitted_answers;
        });

        // Now, you have an array of "processed_answers" data
        console.log(processedAnswers);
        setProcessedDataFromServer(processedAnswers);
        // You can perform further operations with the data here
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const exportToExcel = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}_${
      currentDate.getMonth() + 1
    }_${currentDate.getFullYear()}`;

    const data = [
      headers, // Header row
      ...processedDataFromServer,
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `результаты_${formattedDate}.xlsx`);
  };

  return (
    <Table>
      <div style={{ position: "fixed", zIndex: 11111 }}>
        <button onClick={exportToExcel}>Скачать Excel</button>
      </div>
      <WrapHead>
        {headers.map((header, index) => (
          <HeadInner key={index}>
            <WrapSpan>{header}</WrapSpan>
          </HeadInner>
        ))}
      </WrapHead>

      {processedDataFromServer?.map((answer, index) => {
        return (
          <WrapAnswers>
            {answer.map((val, num) => (
              <AnswersItem key={num}>{val}</AnswersItem>
            ))}
          </WrapAnswers>
        );
      })}
    </Table>
  );
};

export default Results;
