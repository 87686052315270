import React, { useState } from "react";
import styled from "styled-components";
const Wrapper = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
  &.open {
    display: block;
  }
  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px 20px 30px 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .close-button {
    font-size: 20px;
    cursor: pointer;
    margin: 0 10px;
    padding: 5px 20px 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
  }
`;

const Popup = () => {
  const [isOpen, setIsOpen] = useState(true);

  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    <Wrapper className={`popup ${isOpen ? "open" : ""}`}>
      <div className="popup-content">
        <p>
          Спасибо, что решили пройти наш тест! Это займет всего 20 минут вашего
          времени.
          <br />
          <bold> Ваши ответы важны для нас!</bold>
        </p>
        <span className="close-button" onClick={closePopup}>
          Начать
        </span>
      </div>
    </Wrapper>
  );
};

export default Popup;
