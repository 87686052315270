import styled from "styled-components";

export const Table = styled.div`
  padding: 15px;
  text-align: left;
  //border-bottom: 1px solid #ddd;
  //overflow-y: auto;
  padding-top: 30px;
`;
export const WrapHead = styled.div`
  display: flex;
  //  border-bottom: 2px solid #000;
  position: sticky;
  width: fit-content;
  z-index: 1;
  padding-bottom: 20px;
  top: 0;
  background: #fff;
  left: 15px;
`;

export const HeadInner = styled.div`
  height: 280px;
  width: 100px;
  position: relative;
  display: flex;
  &:nth-child(6) {
    width: 100px;
  }
`;

export const WrapSpan = styled.span`
  writing-mode: tb-rl;
  transform: rotate(-180deg);
`;
export const WrapAnswers = styled.div`
  display: flex;
  border-bottom: 1px solid #000;
  margin: 18px 0;
  width: fit-content;
`;
export const AnswersItem = styled.div`
  width: 100px;
  flex-shrink: 0;
  &:nth-child(6) {
    width: 100px;
    font-size: 12px;
    transform: translateY(-10px);
  }
`;
